import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Heading2, Heading4 } from 'components/elements/Typography';
import Button from 'components/forms/Button';
import { Div } from 'components/utils/Helper';
import Footer from 'components/layouts/Footer';
import DateTimeCard from 'components/forms/DateTimeCard';
import Loader from 'components/layouts/Loader';
import Error from 'views/error/pages/Error';
import { getJapaneseDay, moment } from 'utils/momentUtils';
import { isPageTypeIrregular } from 'utils/calendarUtils';
import { resetCalendarDetail } from 'redux/reducers/timeSlotsReducer';
import { useAppointmentDetails, cancelAppointment } from '../hooks/serverUtils';
import cancelled from '../../../assets/img/cancelled.png';
import messages from '../i18n/appointmentCancellation';

const MessageArea = styled.div`
  white-space: pre;
`;
const SuccessIcon = styled.div`
  display: flex;
  padding: 45px 0px 25px;
  justify-content: center;
  margin: auto;
`;

function getAppointmentDetails(appointment) {
  const beginAt = moment(appointment.beginAt);
  const endAt = moment(appointment.endAt);
  const weekDay = beginAt.format('dddd');
  const year = beginAt.year();
  const month = beginAt.format('MM');
  const date = beginAt.date();
  const day = getJapaneseDay(weekDay);
  const time = beginAt.tz(appointment.timeZone)?.format('HH:mm');
  const nextHour = endAt.tz(appointment.timeZone)?.format('HH:mm');
  const timeZone = { label: appointment.timeZone };

  return { year, month, date, day, time, nextHour, timeZone };
}
export function AppointmentCancellation(props) {
  const params = useParams();
  const dispatch = useDispatch();
  const { feature, history } = props;
  const [showLoader, setShowLoader] = useState(false);
  const appointmentDetail = useAppointmentDetails();
  const isIrregular = isPageTypeIrregular(appointmentDetail, feature);
  const { year, month, date, day, time, nextHour, timeZone } =
    getAppointmentDetails(appointmentDetail) || {
      year: '202?',
      month: '??',
      date: '??',
      day: '??',
      time: '??:??',
      nextHour: '??:??',
      timeZone: 'Asia/Tokyo',
    };
  const appointmentCancellationDetail = useSelector(
    state => state.appointmentDetail,
  );

  useEffect(() => {
    if (
      appointmentCancellationDetail.isSuccess ||
      appointmentCancellationDetail.hasError
    ) {
      setShowLoader(false);
    }
  }, [appointmentCancellationDetail]);

  function generateRedirectUrl() {
    let pathname = `/${params.calendar_alias}${
      isIrregular ? '' : `/${params.duration}`
    }`;
    if (appointmentCancellationDetail.oneTimeAppointment) {
      const { url, duration } =
        appointmentCancellationDetail.oneTimeAppointment;
      pathname = `/${url}${isIrregular ? '' : `/${duration}`}`;
    }

    return pathname;
  }

  function handleOnClick() {
    if (appointmentCancellationDetail.isSuccess) {
      dispatch(resetCalendarDetail());
      const pathname = generateRedirectUrl();
      history.replace({
        pathname,
      });
    } else {
      setShowLoader(true);
      cancelAppointment(
        params.calendar_alias,
        params.appointment_uid,
        appointmentCancellationDetail,
        dispatch,
      );
    }
  }

  if (appointmentDetail.hasError || appointmentCancellationDetail.hasError) {
    const errorMessage =
      appointmentDetail.message || appointmentCancellationDetail.message;

    return <Error message={errorMessage} />;
  }

  if (!appointmentDetail.isSuccess || showLoader) {
    return <Loader />;
  }
  return (
    <>
      <Div textAlign="center" mt="30px">
        <Heading2 margin="10px">
          {appointmentCancellationDetail.isSuccess ? (
            <FormattedMessage {...messages.appointmentCancelled} />
          ) : (
            <FormattedMessage {...messages.appointmentCancellation} />
          )}
        </Heading2>
        {!appointmentCancellationDetail.isSuccess && (
          <MessageArea>
            <Heading4>
              <FormattedMessage {...messages.appointmentCancellationRequest} />
              <br />
              <FormattedMessage {...messages.appointmentCancellationWarning} />
            </Heading4>
          </MessageArea>
        )}
      </Div>
      {appointmentCancellationDetail.isSuccess ? (
        <SuccessIcon>
          <img width="71px" height="98px" src={cancelled} alt="cancelled" />
        </SuccessIcon>
      ) : (
        <Div
          display="flex"
          textAlign="center"
          justifyContent="center"
          mt="19px"
          mb="19px"
        >
          <DateTimeCard
            width="300px"
            year={year}
            month={month}
            date={date}
            day={day}
            time={time}
            nextHour={nextHour}
            timezone={timeZone}
          />
        </Div>
      )}
      {appointmentCancellationDetail.schedulable && (
        <Div display="flex" justifyContent="center">
          <Button
            outlined
            width="300px"
            outlinedColor={
              appointmentCancellationDetail.isSuccess ? '#3fbfba' : '#d83636'
            }
            invertOnHover
            onClick={handleOnClick}
          >
            {appointmentCancellationDetail.isSuccess ? (
              <FormattedMessage
                {...messages.appointmentCancellationReschedule}
              />
            ) : (
              <FormattedMessage {...messages.appointmentCancellationButton} />
            )}
          </Button>
        </Div>
      )}
      {!appointmentCancellationDetail.isSuccess && (
        <Div textAlign="center" mt="30px">
          <Heading4>
            <FormattedMessage {...messages.appointmentCancellationInfo} />
          </Heading4>
        </Div>
      )}
      <Footer mt="45px" />
    </>
  );
}

AppointmentCancellation.propTypes = {
  feature: PropTypes.string.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};
