import { defineMessages } from 'react-intl';

export default defineMessages({
  persons: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.persons',
    defaultMessage: '{guests}persons',
  },
  person: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.person',
    defaultMessage: '{guests}person',
  },
  availableParticipants: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.availableParticipants',
    defaultMessage: 'Available participants',
  },
  metaWithinBookingRange: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.meta.withinBookingRange',
    defaultMessage: 'Within Booking Range',
  },
  metaWithinBusinessHours: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.meta.withinBusinessHours',
    defaultMessage: 'Within Business Hours',
  },
  metaOverlapWithBuffer: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.meta.overlapWithBuffer',
    defaultMessage: 'Overlap with Buffer',
  },
  metaAvailableAttendees: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.meta.availableAttendees',
    defaultMessage: 'Available Attendees',
  },
  metaUnavailableAttendees: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.meta.unavailableAttendees',
    defaultMessage: 'Unavailable Attendees',
  },
  metaAvailableResources: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.meta.availableResources',
    defaultMessage: 'Available Resources',
  },
  metaUnavailableResources: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.meta.unavailableResources',
    defaultMessage: 'Unavailable Resources',
  },
  yes: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.meta.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'booking.bigCalendar.calendarBody.timeSlots.meta.no',
    defaultMessage: 'No',
  },
});
