import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label as BaseLabel } from '@d-lighted/design-system';

import { formatMultiTime, formatDate } from 'components/utils/FormatMultiTime';
import { useLocale } from 'hooks/useLocale';

const Label = styled(BaseLabel)`
  margin-left: 15px;
`;

const CardDiv = styled.div`
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  width: ${props => props.width || '100%'};
`;

const Card = styled.div`
  border-radius: 5px;
  border: solid 1px #ffffff;
  background-color: #ffffff;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const CardBody = styled.div`
  padding: ${props => (props.multiguest ? '18px 18px 0' : '18px')};
  display: flex;
  flex-direction: column;
  ${props =>
    props.centerItems &&
    `
    align-items: center;
    justify-content: center;
  `}
`;

const CardDate = styled.div`
  opacity: 0.6;
  font-size: 15px;
  line-height: 1.6;
  color: #314143;
  margin-bottom: 8px;
`;

const CardTime = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.45;
  color: #314143;
`;

const CardTimezone = styled.div`
  opacity: 0.6;
  font-size: 12px;
  line-height: 1.4;
  color: #314143;
  margin-bottom: 8px;
`;

const DateTimeCardWithLabel = ({
  timezone,
  year,
  month,
  date,
  day,
  time,
  nextHour,
  centerItems,
  label,
  width,
  mt,
  mb,
  isIrregular,
  isMultiTime,
  slots,
}) => {
  const [locale] = useLocale();

  return (
    <>
      {label && <Label>{label}</Label>}
      <CardDiv width={width} mt={mt} mb={mb}>
        <Card>
          {isMultiTime ? (
            formatMultiTime(slots, centerItems, timezone, locale)
          ) : (
            <CardBody centerItems={centerItems}>
              {!isIrregular && (
                <>
                  {timezone && (
                    <CardTimezone>
                      <i className="fa fa-globe" aria-hidden="true" />{' '}
                      {timezone.label}
                    </CardTimezone>
                  )}
                  <CardDate>
                    {formatDate({ year, month, date, day }, locale)}
                  </CardDate>
                  <CardTime>
                    {time} - {nextHour}
                  </CardTime>
                </>
              )}
            </CardBody>
          )}
        </Card>
      </CardDiv>
    </>
  );
};

DateTimeCardWithLabel.defaultProps = {
  timezone: { label: 'UTC+9:00 Tokyo', code: 'Asia/Tokyo' },
  year: '2020',
  month: '07',
  date: '31',
  day: 1,
  time: '11:00',
  nextHour: '12:00',
  centerItems: false,
  mt: null,
  mb: null,
  label: null,
  width: null,
  isIrregular: false,
  slots: [],
  isMultiTime: false,
};

DateTimeCardWithLabel.propTypes = {
  year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  month: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  day: PropTypes.number,
  time: PropTypes.string,
  nextHour: PropTypes.string,
  centerItems: PropTypes.bool,
  mt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  mb: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  label: PropTypes.string,
  width: PropTypes.string,
  timezone: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isIrregular: PropTypes.bool,
  isMultiTime: PropTypes.bool,
  slots: PropTypes.oneOfType([PropTypes.array]),
};

export default DateTimeCardWithLabel;
