import { defineMessages } from 'react-intl';

export default defineMessages({
  defaultInstruction: {
    id: 'confirmation.form.visitorFieldsGroup.defaultInstruction',
    defaultMessage:
      'Each line should have name, company name and email address.',
  },
  splitNameIsEnabledInstruction: {
    id: 'confirmation.form.visitorFieldsGroup.splitNameIsEnabledInstruction',
    defaultMessage:
      'Each line should have first name, last name, company name and email address.',
  },
  companyIsHiddenInstruction: {
    id: 'confirmation.form.visitorFieldsGroup.companyIsHiddenInstruction',
    defaultMessage: 'Each line should have name and email address.',
  },
  companyIsHiddenWhenSplitNameIsEnabledInstruction: {
    id: 'confirmation.form.visitorFieldsGroup.companyIsHiddenWhenSplitNameIsEnabledInstruction',
    defaultMessage:
      'Each line should have first name last name and email address.',
  },
  nameIsHiddenInstruction: {
    id: 'confirmation.form.visitorFieldsGroup.nameIsHiddenInstruction',
    defaultMessage: 'Each line should have company name and email address.',
  },
  nameIsHiddenWhenSplitNameIsEnabledInstruction: {
    id: 'confirmation.form.visitorFieldsGroup.nameIsHiddenWhenSplitNameIsEnabledInstruction',
    defaultMessage:
      'Each line should have first name company name and email address.',
  },
  nameAndCompanyAreHiddenInstruction: {
    id: 'confirmation.form.visitorFieldsGroup.nameAndCompanyAreHiddenInstruction',
    defaultMessage: 'Each line should have email address.',
  },
  nameAndCompanyAreHiddenWhenSplitNameIsEnabledInstruction: {
    id: 'confirmation.form.visitorFieldsGroup.nameAndCompanyAreHiddenWhenSplitNameIsEnabledInstruction',
    defaultMessage: 'Each line should have first name email address.',
  },
  fullName: {
    id: 'confirmation.form.visitorFieldsGroup.fullName',
    defaultMessage: 'Full Name',
  },
  firstName: {
    id: 'confirmation.form.visitorFieldsGroup.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'confirmation.form.visitorFieldsGroup.lastName',
    defaultMessage: 'Last Name',
  },
  companyName: {
    id: 'confirmation.form.visitorFieldsGroup.companyName',
    defaultMessage: 'Company Name',
  },
  companyNameHint: {
    id: 'confirmation.form.visitorFieldsGroup.companyName.hint',
    defaultMessage: '(If you are an individual, please enter "individual")',
  },
  mailAddress: {
    id: 'confirmation.form.visitorFieldsGroup.mailAddress',
    defaultMessage: 'Email Address',
  },
  errMsg: {
    id: 'confirmation.form.visitorFieldsGroup.multiguest.error',
    defaultMessage: 'You have reached the maximum number you can add.',
  },
  addBtn: {
    id: 'confirmation.form.visitorFieldsGroup.multiguest.addBtn',
    defaultMessage: 'Add more guests',
  },
  batchAddBtn: {
    id: 'confirmation.form.visitorFieldsGroup.multiguest.batchAdd',
    defaultMessage: 'Bulk add guests information',
  },
  optionalLastname: {
    id: 'validation.optionalLastname',
    defaultMessage: 'Lastname is required.',
  },
  optionalFirstname: {
    id: 'validation.optionalFirstname',
    defaultMessage: 'Firstname is required.',
  },
});
