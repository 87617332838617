import { defineMessages } from 'react-intl';

export default defineMessages({
  batchAddTitle: {
    id: 'confirmation.form.visitorFieldsGroup.multiguest.batchAdd',
    defaultMessage: 'Collective registration of attendee information',
  },
  info1: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.information1',
    defaultMessage: 'Copy guests info from Excel file and',
  },
  info2: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.information2',
    defaultMessage: 'paste it in the text area below.',
  },
  info3: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.information3',
    defaultMessage: 'For details on how to use, ',
  },
  infoLink: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.informationLink',
    defaultMessage: 'click here',
  },
  placeholderCompanyIsHidden: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.textArea.placeholderCompanyIsHidden',
    defaultMessage: 'Name 1 Email Address 1 \nName 2 Email Address 2',
  },
  placeholderCompanyIsHiddenWhenSplitNameIsEnabled: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.textArea.placeholderCompanyIsHiddenWhenSplitNameIsEnabled',
    defaultMessage:
      'First Name 1 Last Name 1 Email Address 1 \nFirst Name 2 Last Name 2 Email Address 2',
  },
  defaultPlaceholder: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.textArea.defaultPlaceholder',
    defaultMessage:
      'Name 1 Company name 1 Email address 1 \nName 2 Company name 2 Email address 2',
  },
  defaultPlaceholderWhenSplitNameIsEnabled: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.textArea.defaultPlaceholderWhenSplitNameIsEnabled',
    defaultMessage:
      'First Name 1 Last Name 1 Company name 1 Email address 1 \nFirst Name 2 Last Name 2 Company name 2 Email address 2',
  },
  placeholderNameAndCompanyAreHidden: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.textArea.placeholderNameAndCompanyAreHidden',
    defaultMessage: 'Email Address 1 \nEmail Address 2',
  },
  placeholderNameAndCompanyAreHiddenWhenSplitNameIsEnabled: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.textArea.placeholderNameAndCompanyAreHiddenWhenSplitNameIsEnabled',
    defaultMessage:
      'First Name 1 Email Address 1 \nFirst Name 2 Email Address 2',
  },
  placeholderNameIsHidden: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.textArea.placeholderNameIsHidden',
    defaultMessage:
      'Company name 1 Email address 1 \nCompany name 2 Email address 2',
  },
  placeholderNameIsHiddenWhenSplitNameIsEnabled: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.textArea.placeholderNameIsHiddenWhenSplitNameIsEnabled',
    defaultMessage:
      'First name 1 Company name 1 Email address 1 \nFirst name 2 Company name 2 Email address 2',
  },
  addBtn: {
    id: 'confirmation.form.visitorFieldsGroup.batchVisitorAddModal.addBtn',
    defaultMessage: 'ADD',
  },
});
