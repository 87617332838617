import { find } from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';

import { globalActions } from 'constants/globalActions';
import { useGet, _post } from 'utils/api';
import { formatAxiosResponse, isStateNew } from 'utils/apiUtils';

export function useAppointmentDetails() {
  const params = useParams();
  const appointmentDetail = useSelector(state => state.calendarDetail);
  const fetchAppointmentDetails = find(globalActions, {
    name: 'calendarDetail',
  });
  fetchAppointmentDetails.url = `booking_calendars/${params.calendar_alias}/appointments/${params.appointment_uid}/cancellations`;
  useGet(fetchAppointmentDetails);
  return appointmentDetail;
}

export function cancelAppointment(
  calendarAlias,
  appoUid,
  appoDetail,
  dispatch,
) {
  const cancelAppointmentDetails = find(globalActions, {
    name: 'appointmentDetail',
  });
  const postAction = createAction(`${cancelAppointmentDetails.name}_update`);
  cancelAppointmentDetails.url = `booking_calendars/${calendarAlias}/appointments/${appoUid}/cancellations`;
  _post(cancelAppointmentDetails.url).then(requestResponse => {
    const formattedResponse = formatAxiosResponse(requestResponse);
    if (isStateNew(appoDetail, formattedResponse)) {
      dispatch(postAction(formattedResponse));
    }
    return 0;
  });

  return appoDetail;
}
