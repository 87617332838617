import { defineMessages } from 'react-intl';

export default defineMessages({
  appointmentCancelled: {
    id: 'appointment.cancellation.successful',
    defaultMessage: 'Appointment cancelled succesfully',
  },
  appointmentCancellation: {
    id: 'appointment.cancellation',
    defaultMessage: 'Cancel Appointment',
  },
  appointmentCancellationRequest: {
    id: 'appointment.cancellation.request',
    defaultMessage: 'Would you like to cancel this appointment',
  },
  appointmentCancellationWarning: {
    id: 'appointment.cancellation.warning',
    defaultMessage: 'After cancellation, this operation cannot be undone',
  },
  appointmentCancellationReschedule: {
    id: 'appointment.cancellation.reschedule',
    defaultMessage: 'Reschedule Appointment',
  },
  appointmentCancellationButton: {
    id: 'appointment.cancellation.button',
    defaultMessage: 'Cancel Appointment',
  },
  appointmentCancellationInfo: {
    id: 'appointment.cancellation.info',
    defaultMessage: 'You can reschedule another appointment after cancellation',
  },
});
