import * as constants from 'constants/index';

import { AppointmentCancellation } from './pages/AppointmentCancellation';

export const appointmentCancellationRoutes = [
  {
    component: AppointmentCancellation,
    path: `/:calendar_alias/cancellation/:appointment_uid/:duration${constants.durations}`,
    exact: true,
    feature: 'appointmentCancellation',
  },
];
