import React from 'react';
import { StyledLink } from 'components/utils/Helper';

export const generatePrivacyPolicy = (
  text = '{個人情報取り扱い}に同意します。',
  privacyPolicyUrl,
  registerClickLinkHelpAction,
) => {
  const chunks = text.split(/({[^}]*})/);

  return chunks.map(chunk => {
    if (chunk.match(/{[^}]*}/)) {
      const customText = chunk.slice(1, -1);
      return (
        <StyledLink
          tabIndex="-1"
          href={privacyPolicyUrl}
          target="_blank"
          color="#00bbb5"
          onClick={registerClickLinkHelpAction}
        >
          {customText}
        </StyledLink>
      );
    }
    return chunk;
  });
};
